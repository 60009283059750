<template>
    <div>
        <bread-crumb :pageTitle="'Checkout'"></bread-crumb>
        <div class="container pb-5 mb-sm-4 mt-n2 mt-md-n3">
            <!-- Steps (wizard)-->
            <checkout-steps :stepNumber="3"></checkout-steps>
            <div class="row pt-4 mt-2">
                <!-- Content-->
                <div class="col-xl-9 col-md-8">
                    <h2 class="h6 px-4 py-3 bg-secondary mb-4">Choose shipping method</h2>
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                            <tr>
                                <th class="align-middle"></th>
                                <th class="align-middle">Shipping method</th>
                                <th class="align-middle">Delivery time</th>
                                <th class="align-middle">Shipping fee</th>
                            </tr>
                            </thead>
                            <tbody>
                                <template v-if="isUSShippingAddress">
                                    <tr>
                                        <td>
                                            <div class="custom-control custom-radio mb-4">
                                                <input class="custom-control-input" type="radio" id="default-shipping" name="shipping-method" v-on:change="saveShippingMethod" v-model="shippingMethod" value="0">
                                                <label class="custom-control-label" for="default-shipping"></label>
                                            </div>
                                        </td>
                                        <td class="align-middle"><span class="text-dark">Default Shipping</span><br><span class="text-muted font-size-sm">All addresses in United States</span></td>
                                        <td class="align-middle">5 - 7 days</td>
                                        <td class="align-middle">$9.30</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-control custom-radio mb-4">
                                                <input class="custom-control-input" type="radio" id="fedex-express" name="shipping-method" v-on:change="saveShippingMethod" v-model="shippingMethod" value="1">
                                                <label class="custom-control-label" for="fedex-express"></label>
                                            </div>
                                        </td>
                                        <td class="align-middle"><span class="text-dark">FedEx Express Saver®</span><br><span class="text-muted font-size-sm">All addresses in United States</span></td>
                                        <td class="align-middle">3 - 4 days</td>
                                        <td class="align-middle">$12.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-control custom-radio mb-4">
                                                <input class="custom-control-input" type="radio" id="fedex-2day" name="shipping-method" v-on:change="saveShippingMethod" v-model="shippingMethod" value="2">
                                                <label class="custom-control-label" for="fedex-2day"></label>
                                            </div>
                                        </td>
                                        <td class="align-middle"><span class="text-dark">FedEx 2 Day</span><br><span class="text-muted font-size-sm">All addresses in United States</span></td>
                                        <td class="align-middle">2 days</td>
                                        <td class="align-middle">$25.00</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td>
                                            <div class="custom-control custom-radio mb-4">
                                                <input class="custom-control-input" type="radio" id="usps-international" name="shipping-method" v-on:change="saveShippingMethod" v-model="shippingMethod" value="3">
                                                <label class="custom-control-label" for="usps-international"></label>
                                            </div>
                                        </td>
                                        <td class="align-middle"><span class="text-dark">USPS International</span><br><span class="text-muted font-size-sm">All addresses outside United States</span></td>
                                        <td class="align-middle">Varies by Country</td>
                                        <td class="align-middle">$39.90</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <!-- Navigation-->
                    <div class="d-flex pt-2">
                        <div class="w-50 pr-3"><router-link :to="{ name: 'checkout-details'}" class="btn btn-outline-secondary btn-block"><i class="mr-1" data-feather="chevron-left"></i>Back</router-link></div>
                        <div class="w-50 pl-2"><router-link :to="{ name: 'checkout-payment'}" class="btn btn-primary btn-block"><span class="d-none d-sm-inline">Proceed to payment</span><span class="d-inline d-sm-none">Next</span><i class="ml-1" data-feather="chevron-right"></i></router-link></div>
                    </div>
                </div>
                <!-- Sidebar-->
                <checkout-order-summary v-bind:show-promo-code-form="false"></checkout-order-summary>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumb from "../components/BreadCrumb";
    import CheckoutSteps from "../components/CheckoutSteps";
    import CheckoutOrderSummary from "../components/CheckoutOrderSummary";

    export default {
        name: "CheckoutShippingPage",
        components: {BreadCrumb, CheckoutSteps, CheckoutOrderSummary},
        data: function() {
            return {
                shippingMethod: this.$store.state.shippingMethod,
                isUSShippingAddress: (this.$store.state.shippingAddress.country === 'US')
            }
        },
        methods: {
            saveShippingMethod: function() {
                this.$store.dispatch('setShippingMethod', this.shippingMethod);
                if (this.shippingMethod === "0") {
                    this.$store.dispatch('setShippingAmount', 9.30);
                    return 9.30;
                }
                else if (this.shippingMethod === "1") {
                    this.$store.dispatch('setShippingAmount', 12.00);
                    return 12.00;
                }
                else if (this.shippingMethod === "2") {
                    this.$store.dispatch('setShippingAmount', 20);
                    return 20.00;
                }
                else if (this.shippingMethod === "3") {
                    this.$store.dispatch('setShippingAmount', 39.90);
                    return 39.90;
                }
            },
        },
        mounted() {
            if ((this.isUSShippingAddress) && (this.shippingMethod === "3")) {
                this.shippingMethod = "0";
            }
            if ((! this.isUSShippingAddress) && (this.shippingMethod !== "3")) {
                this.shippingMethod = "3";
            }
            this.saveShippingMethod();
        }
    }
</script>

<style scoped>
</style>
