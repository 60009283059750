<template>
    <div>
        <div class="page-title-wrapper" aria-label="Page title">
            <div class="container">
                <h1 class="page-title">Retailer Pricing</h1><span class="d-block mt-2 text-muted"></span>
                <hr class="mt-4">
            </div>
        </div>
        <div class="container pb-2">
            <div class="card">
                <div class="card-body">
                    <p class="card-text">Click <a target="_blank" href="https://gemex.com/diamondeye-retailer-outline-1/">here</a> for explanation of DiamondEye program pricing.</p>
                </div>
            </div>
        </div>
        <div class="container pb-2">
            <div class="comparison-table">
                <table class="mt-3 table table-bordered">
                    <tbody id="summary" data-filter="target">
                        <tr>
                            <td>
                                <p>Five registrations included with each DiamondEye Kit: $49.99</p>
                                <button type="button" class="btn btn-primary mb-2 mr-2" @click.stop="addProductToCart('RETAILER_VIEWER_KIT')">Purchase Additional DiamondEye Kit {{ retailerViewerKitPrice }}</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>10 DiamondEye Kits with one consumer registration and three retailer credits included for each kit: $299.90</p>
                                <button type="button" class="btn btn-primary mb-2 mr-2" @click.stop="addProductToCart('RETAILER_VIEWER_KIT_10')">Purchase Additional DiamondEye Kit {{ retailerTenViewerKitPrice }}</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Verification: $0 for 2022 and 2023</p>
                                <button type="button" class="btn btn-primary mb-2 mr-2" @click.stop="addProductToCart('RETAILER_VERIFICATION')">Purchase Additional Verification {{ retailerVerificationPrice }}</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Additional Diamond Registration: $4.99 per diamond</p>
                                <button type="button" class="btn btn-primary mb-2 mr-2" @click.stop="addProductToCart('RETAILER_REGISTRATION')">Purchase Additional Registration {{ retailerRegistrationPrice }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="container pb-5">
            <div class="card">
                <div class="card-body">
                    <p class="card-text">Please <a href="mailto:info@gemex.com">Contact Us</a> for volume discounts.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../router";
    import {formatAsCurrency} from "../helper";

    export default {
        name: "PlanComparison",
        methods: {
            login() {
                this.$auth.login({ target: this.$route.path });
            },
            addProductToCart(productKey) {
                const product = this.$store.getters.getProductByProductKeyAndPlanKey(productKey, null);
                this.$store.dispatch('addProductToCart', product.id);
                router.push({ path: '/cart' });
            },
        },
        computed: {
            isAuthenticated() {
                return this.$auth.isAuthenticated();
            },
            hasActivePlan() {
                return this.$store.getters.hasActivePlan;
            },
            currentPlan() {
                return this.$store.getters.getCurrentPlan;
            },
            retailerViewerKitPrice() {
                const product = this.$store.getters.getProductByProductKeyAndPlanKey('RETAILER_VIEWER_KIT', null);
                return formatAsCurrency(product.price);

            },
            retailerTenViewerKitPrice() {
                const product = this.$store.getters.getProductByProductKeyAndPlanKey('RETAILER_VIEWER_KIT_10', null);
                return formatAsCurrency(product.price);

            },
            retailerRegistrationPrice() {
                const product = this.$store.getters.getProductByProductKeyAndPlanKey('RETAILER_REGISTRATION', null);
                return formatAsCurrency(product.price);

            },
            retailerVerificationPrice() {
                const product = this.$store.getters.getProductByProductKeyAndPlanKey('RETAILER_VERIFICATION', null);
                return formatAsCurrency(product.price);
            },
        }
    }
</script>

<style scoped>
    td {
        font-size: 1.25em;
    }
</style>
