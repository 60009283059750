<template>
    <div>
        <bread-crumb :pageTitle="'Checkout'"></bread-crumb>
        <div class="container pb-5 mb-sm-4 mt-n2 mt-md-n3">
            <!-- Steps (wizard)-->
            <checkout-steps :stepNumber="2"></checkout-steps>
            <div class="row pt-4 mt-2">
                <!-- Content-->
                <div class="col-xl-9 col-md-8">
                    <h2 class="h6 px-4 py-3 bg-secondary">Enter your information</h2>
                    <form class="ml-3 needs-validation" novalidate>
                        <h6 class="mb-3 pt-4 pb-3 border-bottom">Billing address</h6>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-firstname">First Name</label>
                                    <input class="form-control" type="text" v-model.trim="$v.billingAddress.firstName.$model" id="checkout-billing-firstname" :class="{ 'is-invalid': $v.billingAddress.firstName.$error }">
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.firstName.required">Please enter a first name</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-lastname">Last Name</label>
                                    <input class="form-control" type="text" v-model.trim="$v.billingAddress.lastName.$model" id="checkout-billing-lastname" :class="{ 'is-invalid': $v.billingAddress.lastName.$error }">
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.lastName.required">Please enter a last name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-email">E-mail Address</label>
                                    <input class="form-control" type="text" v-model.trim="$v.billingAddress.emailAddress.$model" id="checkout-billing-email" :class="{ 'is-invalid': $v.billingAddress.emailAddress.$error }">
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.emailAddress.required">Please enter an email address</div>
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.emailAddress.email">Please enter a valid email address</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-phone">Phone Number</label>
                                    <input class="form-control" type="text" @keyup="formatPhone($event,'billing')" v-model.trim="$v.billingAddress.phone.$model" id="checkout-billing-phone" :class="{ 'is-invalid': $v.billingAddress.phone.$error }">
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.phone.required">Please enter a phone number</div>
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.phone.validPhone">Please enter a valid phone number</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-address-1">Address 1</label>
                                    <input class="form-control" type="text" v-model.trim="$v.billingAddress.address1.$model" id="checkout-billing-address-1" :class="{ 'is-invalid': $v.billingAddress.address1.$error }">
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.address1.required">Please enter an address</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-address-2">Address 2</label>
                                    <input class="form-control" type="text" v-model.trim="billingAddress.address2" id="checkout-billing-address-2">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-city">City</label>
                                    <input class="form-control" type="text" v-model.trim="$v.billingAddress.city.$model" id="checkout-billing-city" :class="{ 'is-invalid': $v.billingAddress.city.$error }">
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.city.required">Please enter a city</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-state">State</label>
                                    <region-select class="form-control custom-select" v-model.trim="$v.billingAddress.state.$model" id="checkout-billing-state" :country="$v.billingAddress.country.$model" :region="$v.billingAddress.state.$model" />
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.state.required">Please select a state</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-zip">Postal Code</label>
                                    <input class="form-control" type="text" @keyup="formatPostalCode($event, 'billing')" v-model.trim="$v.billingAddress.postalcode.$model" id="checkout-billing-zip" :class="{ 'is-invalid': $v.billingAddress.postalcode.$error }">
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.postalcode.required">Please enter a postal code</div>
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.postalcode.validPostalCode">Please enter a valid postal code</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="checkout-billing-country">Country</label>
                                    <country-select class="form-control custom-select" v-model="$v.billingAddress.country.$model" id="checkout-billing-country" :country="$v.billingAddress.country.$model" topCountry="US"  />
                                    <div class="invalid-feedback" v-if="!$v.billingAddress.country.required">Please select a country</div>
                                </div>
                            </div>
                        </div>
                        <div :class="{ 'd-none': ! requiresShipping }">
                            <h6 class="mb-3 pt-2 pb-3 border-bottom">Shipping address</h6>
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" v-model="isSameAsBilling" type="checkbox" @change="sameAsBilling()" id="same-address">
                                        <label class="custom-control-label" for="same-address">Same as billing address</label>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="shipping-address-panel">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-firstname">First Name</label>
                                            <input class="form-control" type="text" v-model.trim="$v.shippingAddress.firstName.$model" id="checkout-firstname" :class="{ 'is-invalid': $v.shippingAddress.firstName.$error }">
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.firstName.required">Please enter a first name</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-lastname">Last Name</label>
                                            <input class="form-control" type="text" v-model.trim="$v.shippingAddress.lastName.$model" id="checkout-lastname" :class="{ 'is-invalid': $v.shippingAddress.lastName.$error }">
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.lastName.required">Please enter a last name</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-email">E-mail Address</label>
                                            <input class="form-control" type="text" v-model.trim="$v.shippingAddress.emailAddress.$model" id="checkout-email" :class="{ 'is-invalid': $v.shippingAddress.emailAddress.$error }">
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.emailAddress.required">Please enter an email address</div>
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.emailAddress.email">Please enter a valid email address</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-phone">Phone Number</label>
                                            <input class="form-control" type="text" @keyup="formatPhone($event,'shipping')" v-model.trim="$v.shippingAddress.phone.$model" id="checkout-phone" :class="{ 'is-invalid': $v.shippingAddress.phone.$error }">
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.phone.required">Please enter a phone number</div>
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.phone.validPhone">Please enter a valid phone number</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-address-1">Address 1</label>
                                            <input class="form-control" type="text" v-model.trim="$v.shippingAddress.address1.$model" id="checkout-address-1" :class="{ 'is-invalid': $v.shippingAddress.address1.$error }">
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.address1.required">Please enter an address</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-address-2">Address 2</label>
                                            <input class="form-control" type="text" v-model.trim="shippingAddress.address2" id="checkout-address-2">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-city">City</label>
                                            <input class="form-control" type="text" v-model.trim="$v.shippingAddress.city.$model" id="checkout-city" :class="{ 'is-invalid': $v.shippingAddress.city.$error }">
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.city.required">Please enter a city</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-state">State</label>
                                            <region-select class="form-control custom-select" v-model.trim="$v.shippingAddress.state.$model" id="checkout-state" :country="$v.shippingAddress.country.$model" :region="$v.shippingAddress.state.$model" />
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.state.required">Please select a state</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-zip">Postal Code</label>
                                            <input class="form-control" type="text" @keyup="formatPostalCode($event, 'shipping') " v-model.trim="$v.shippingAddress.postalcode.$model" id="checkout-zip" :class="{ 'is-invalid': $v.shippingAddress.postalcode.$error }">
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.postalcode.required">Please enter a postal code</div>
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.postalcode.validPostalCode">Please enter a valid postal code</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkout-country">Country</label>
                                            <country-select class="form-control custom-select" v-model="$v.shippingAddress.country.$model" id="checkout--country" :country="$v.shippingAddress.country.$model" topCountry="US"  />
                                            <div class="invalid-feedback" v-if="!$v.shippingAddress.country.required">Please select a country</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- Navigation-->
                    <div class="d-flex pt-4 mt-2">
                        <div class="w-50 pr-3"><router-link :to="{ name: 'cart'}" class="btn btn-outline-secondary btn-block"><i class="mr-1" data-feather="chevron-left"></i><span class="d-none d-sm-inline">Back to cart</span><span class="d-inline d-sm-none">Back</span></router-link></div>
                        <div class="w-50 pl-2"><a class="btn btn-primary btn-block" v-bind:class="{ disabled: isMissingCheckoutDetails}" href="#" @click.prevent="saveCheckoutDetails()"><span class="d-none d-sm-inline">{{ nextStepLabel }}</span><span class="d-inline d-sm-none">Next</span><i class="ml-1" data-feather="chevron-right"></i></a></div>
                    </div>
                </div>
                <!-- Sidebar-->
                <checkout-order-summary v-bind:show-promo-code-form="false"></checkout-order-summary>
            </div>
        </div>
    </div>
</template>

<script>
    import router from './../router';
    import BreadCrumb from "../components/BreadCrumb";
    import CheckoutSteps from "../components/CheckoutSteps";
    import CheckoutOrderSummary from "../components/CheckoutOrderSummary";
    import { required, email, helpers } from 'vuelidate/lib/validators';

    const validPhone = helpers.regex('validPhone', /^\d{3}-\d{3}-\d{4}$/);
    const validPostalCode = helpers.regex('validPostalCode', /^\d{5}(-\d{4})?$/);
    const isSelected = (value) => {
        return !(value === '' || value === null || value === undefined);
    };

    export default {
        name: "CheckoutDetailsPage",
        components: {BreadCrumb, CheckoutSteps, CheckoutOrderSummary},
        data() {
            return {
                shippingAddress: Object.assign({}, this.$store.state.shippingAddress),
                billingAddress: Object.assign({}, this.$store.state.billingAddress),
                isSameAsBilling: true,
                requiresShipping: this.$store.getters.getRequiresShipping
            }
        },
        validations() {
            var billingAddress = {
                firstName: { required },
                lastName: { required },
                emailAddress: { required, email },
                phone: { required },
                address1: { required },
                city: { required },
                state: { required, isSelected },
                postalcode: {},
                country: { required, isSelected }
            }
            var shippingAddress = {
                firstName: { required },
                lastName: { required },
                emailAddress: { required, email },
                phone: { required },
                address1: { required },
                city: { required },
                state: { required, isSelected },
                postalcode: {},
                country: { required, isSelected }
            }
            if (this.billingAddress.country === 'US') {
                billingAddress.postalcode = { required, validPostalCode};
                billingAddress.phone = { required, validPhone };
            }
            if (this.shippingAddress.country === 'US') {
                shippingAddress.postalcode = { required, validPostalCode};
                shippingAddress.phone = { required, validPhone };
            }

            return { billingAddress, shippingAddress};
        },
        methods: {
            formatPhone(event, addressType) {
                let shouldFormatPhone = false;
                if ((addressType === 'billing') && (this.billingAddress.country === 'US')) {
                    shouldFormatPhone = true;
                }
                if ((addressType === 'shipping') && (this.shippingAddress.country === 'US')) {
                    shouldFormatPhone = true;
                }
                if (shouldFormatPhone) {
                    $('#checkout-billing-firstname').removeClass("is-invalid")
                    var phoneString = event.target.value.replace(/\D/g, "");
                    phoneString = phoneString.substring(0, 10);

                    if (phoneString.length > 6) {
                        phoneString = phoneString.slice(0,3) + "-" + phoneString.slice(3,6)+"-" + phoneString.slice(6);
                    }
                    else if (phoneString.length > 3) {
                        phoneString = phoneString.slice(0,3) + "-" + phoneString.slice(3,6);
                    }
                    event.target.value = phoneString;
                }

            },
            formatPostalCode(event, addressType) {
                let shouldFormatPostalCode = false;
                if ((addressType === 'billing') && (this.billingAddress.country === 'US')) {
                    shouldFormatPostalCode = true;
                }
                if ((addressType === 'shipping') && (this.shippingAddress.country === 'US')) {
                    shouldFormatPostalCode = true;
                }
                if (shouldFormatPostalCode) {
                    var postalCodeString = event.target.value.replace(/\D/g, "");
                    postalCodeString = postalCodeString.substring(0, 9);

                    if (postalCodeString.length > 5) {
                        postalCodeString = postalCodeString.slice(0, 5) + "-" + postalCodeString.slice(5, 9);
                    }
                    event.target.value = postalCodeString;
                }
            },
            sameAsBilling(event) {
                if (this.isSameAsBilling) {
                   $('#shipping-address-panel').collapse('hide');
                }
                else {
                    $('#shipping-address-panel').collapse('show');
                    this.clearShippingAddressFields();
                }
            },
            clearShippingAddressFields() {
                var self = this;
                Object.keys(this.shippingAddress).forEach(function(key, index) {
                    self.shippingAddress[key] = '';
                });
                this.$v.shippingAddress.$reset();
                this.shippingAddress.country = (this.$store.state.shippingAddress.country) ? this.$store.state.shippingAddress.country : 'US';
            },
            saveCheckoutDetails: function() {
                if (this.isSameAsBilling) {
                    this.shippingAddress = Object.assign({}, this.billingAddress);
                }
                this.$store.dispatch('setBillingAddress', this.billingAddress);
                this.$store.dispatch('setShippingAddress', this.shippingAddress);

                if (this.requiresShipping) {
                    router.push({ name: 'checkout-shipping' });
                }
                else {
                    router.push({ name: 'checkout-payment' });
                }
            },
        },
        computed: {
            isMissingCheckoutDetails() {
                return (this.isSameAsBilling) ? (this.$v.billingAddress.$invalid) : (this.$v.$invalid);
            },
            nextStepLabel() {
                return (this.requiresShipping) ? 'Proceed to shipping' : 'Proceed to payment';
            }
        },
        mounted() {
            if (! this.$store.state.billingAddress.country) {
                this.billingAddress.country = 'US';
            }
        }
    }
</script>

<style scoped>
</style>
