<template>
    <div class="container pt-lg-4 pb-5 mb-sm-3">
        <div class="col-md-12">
            <!-- Customer details-->
            <div class="d-flex flex-wrap justify-content-between pb-4">
                <div class="pt-3 mr-3">
                    <h3 class="mb-0">{{ userName }}</h3><span class="font-size-sm text-warning">{{ email }}</span>
                </div>
            </div>
            <ul class="list-unstyled border p-3 mb-4">
                <h5>Your Retailer Account</h5>
                <li v-if="(numOfVerificationsRemaining > 0)" class="pb-1"><span class="opacity-80">You have</span><span class="font-weight-semibold ml-1">{{ numOfVerificationsRemaining }}</span> Diamond Verification Available</li>
                <li v-else><span class="opacity-80">You have no more diamond verification available</span></li>
                <li v-if="(numOfRegistrationRemaining > 0)" class="pb-1"><span class="opacity-80">You have</span><span class="font-weight-semibold ml-1">{{ numOfRegistrationRemaining }}</span> Diamond Registration Available</li>
                <li v-else><span class="opacity-80">You have no more diamond registration available</span></li>
                <h5 class="mt-4">Purchase Additional Services</h5>
                <p>If you have used up one or more of the DiamondEye services (see your remaining availability above), you can purchase additional usages via the buttons below.</p>
                <button type="button" class="btn btn-primary mb-2 mr-2" @click.stop="addProductToCart('RETAILER_VERIFICATION')">Purchase Verification {{ retailerVerificationPrice }}</button>
                <button type="button" class="btn btn-primary mb-2 mr-2" @click.stop="addProductToCart('RETAILER_REGISTRATION')">Purchase Registration {{ retailerRegistrationPrice }}</button>
            </ul>
            <!-- Navigation (visible sm-up)-->
            <ul class="nav nav-tabs d-none d-sm-flex" role="tablist">
                <li class="nav-item"><a class="nav-link active" href="#my-profile" data-toggle="tab" role="tab"><i data-feather="user"></i>&nbsp;My Profile</a>
                <li class="nav-item"><a class="nav-link" href="#my-diamonds" data-toggle="tab" role="tab"><i data-feather="heart"></i>&nbsp;My Diamonds<span class="badge badge-pill badge-secondary bg-0 border ml-2"><span class="text-primary">{{ myDiamonds.length }}</span></span></a></li>
                <li class="nav-item"><a class="nav-link" href="#my-retailer" data-toggle="tab" role="tab"><i data-feather="folder"></i>&nbsp;My Retailer</a></li>
                <li class="nav-item"><a class="nav-link" href="#my-payment-methods" data-toggle="tab" role="tab"><i data-feather="credit-card"></i>&nbsp;Payment Methods<span class="badge badge-pill badge-secondary bg-0 border ml-2"><span class="text-primary">{{ myPaymentMethods.length }}</span></span></a></li>
                <li class="nav-item"><a class="nav-link" href="#my-orders" data-toggle="tab" role="tab"><i data-feather="shopping-bag"></i>&nbsp;My Orders<span class="badge badge-pill badge-secondary bg-0 border ml-2"><span class="text-primary">{{ myOrders.length }}</span></span></a></li>
            </ul>
            <!-- Navigation (visible sm-down)-->
            <div class="d-sm-none pb-4">
                <button class="btn btn-primary btn-block mb-2" type="button" data-toggle="collapse" data-target="#account-menu"><i class="mr-2" data-feather="menu"></i>Menu</button>
                <div class="collapse" id="account-menu">
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action active" href="#my-profile" data-toggle="tab" role="tab"><i class="mr-2" data-feather="user" style="width: 1rem; height: 1rem;"></i>My Profile</a>
                        <a class="list-group-item list-group-item-action" href="#my-diamonds" data-toggle="tab" role="tab"><i class="mr-2" data-feather="heart" style="width: 1rem; height: 1rem;"></i>My Diamonds<span class="badge badge-pill badge-secondary bg-0 border ml-2"><span class="text-primary">{{ myDiamonds.length }}</span></span></a>
                        <a class="list-group-item list-group-item-action" href="#my-retailer" data-toggle="tab" role="tab"><i class="mr-2" data-feather="folder" style="width: 1rem; height: 1rem;"></i>My Retailer</a>
                        <a class="list-group-item list-group-item-action" href="#my-payment-methods" data-toggle="tab" role="tab"><i class="mr-2" data-feather="credit-card" style="width: 1rem; height: 1rem;"></i>Payment Methods<span class="badge badge-pill badge-secondary bg-0 border ml-2"><span class="text-primary">{{ myPaymentMethods.length }}</span></span></a>
                        <a class="list-group-item list-group-item-action" href="#my-orders" data-toggle="tab" role="tab"><i class="mr-2" data-feather="shopping-bag" style="width: 1rem; height: 1rem;"></i>My Orders<span class="badge badge-pill badge-secondary bg-0 border ml-2"><span class="text-primary">{{ myOrders.length }}</span></span></a>
                    </div>
                </div>
            </div>
            <div class="tab-content">
                <!-- Profile info-->
                <div class="tab-pane fade show active" id="my-profile" role="tabpanel">
                    <form class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="account-fn">Name</label>
                                <input class="form-control" type="text" id="account-fn" v-model="userName" required disabled>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="account-email">Email Address</label>
                                <input class="form-control" type="email" id="account-email" v-model="email" required disabled>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- My Diamonds -->
                <div class="tab-pane fade" id="my-diamonds" role="tabpanel">
                    <div v-if="(myDiamonds.length === 0)">
                        You have no registered diamonds.
                    </div>
                    <div v-else class="table-responsive font-size-sm">
                        <table class="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th>Diamond Name</th>
                                    <th>Description</th>
                                    <th>Date Registered</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="myDiamond in myDiamonds">
                                    <td>{{ myDiamond.stoneId }}</td>
                                    <td>{{ myDiamond.idScanCustomerData.description }}</td>
                                    <td>{{ formatDate(myDiamond.scanCreatedDateTime) }}</td>
                                    <td>{{ myDiamond.idScanStatusCode.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--My Retailer-->
                <div class="tab-pane fade" id="my-retailer" role="tabpanel">
                    <form class="row" id="retailer-form">
                        <input class="form-control" type="hidden" id="retailer-id" v-model="myRetailer.id">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="retailer-fn">Retailer Name</label>
                                <input class="form-control" type="text" id="retailer-fn" v-model.trim="$v.myRetailer.name.$model" required :class="{ 'is-invalid': $v.myRetailer.name.$error }">
                                <div class="invalid-feedback" v-if="!$v.myRetailer.name.required">Please enter a retailer name</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="retailer-email">Retailer Prefix</label>
                                <input class="form-control" type="text" id="retailer-prefix" v-model.trim="myRetailer.retailerPrefix" disabled>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="retailer-email">Email Address</label>
                                <input class="form-control" type="email" id="retailer-email" v-model.trim="$v.myRetailer.email.$model" :class="{ 'is-invalid': $v.myRetailer.email.$error }">
                                <div class="invalid-feedback" v-if="!$v.myRetailer.email.email">Please enter a valid email address</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="retailer-ph-number">Phone Number</label>
                                <input class="form-control" type="text" id="retailer-ph-number" @keyup="formatPhone($event)" v-model.trim="$v.myRetailer.phoneNumber.$model" :class="{ 'is-invalid': $v.myRetailer.phoneNumber.$error }">
                                <div class="invalid-feedback" v-if="!$v.myRetailer.phoneNumber.validPhone">Please enter a valid phone number</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="retailer-website-url">Website URL</label>
                                <input class="form-control" type="text" id="retailer-website-url" v-model.trim="$v.myRetailer.websiteURL.$model" :class="{ 'is-invalid': $v.myRetailer.websiteURL.$error }">
                                <div class="invalid-feedback" v-if="!$v.myRetailer.websiteURL.url">Please enter a valid website url</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="retailer-video-url">Promotional Video URL</label>
                                <input class="form-control" type="text" id="retailer-video-url" v-model.trim="$v.myRetailer.videoURL.$model" :class="{ 'is-invalid': $v.myRetailer.videoURL.$error }">
                                <div class="invalid-feedback" v-if="!$v.myRetailer.videoURL.url">Please enter a valid promotional video url</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label></label>
                                <button v-if="(myRetailer.id !== '')" class="btn btn-primary btn-block mt-2" v-bind:class="{ disabled: ! isDisabled}" @click.prevent="updateRetailer()">Update</button>
                                <button v-else-if="(myRetailer.id === '' && canCreateRetailer)" class="btn btn-primary btn-block mt-2" v-bind:class="{ disabled: ! isDisabled}" @click.prevent="createRetailer()">Create</button>
                            </div>
                        </div>
                    </form>
                </div>
                <BlockUI v-if="isRetailerProcessing" :message="retailerProcessMessage">
                    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </BlockUI>
                <!-- My Orders -->
                <div class="tab-pane fade" id="my-orders" role="tabpanel">
                    <div v-if="(myOrders.length === 0)">
                        You have not placed any orders.
                    </div>
                    <div v-else class="table-responsive font-size-sm">
                        <table class="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th>Order #</th>
                                    <th>Order Date</th>
                                    <th>Order Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="myOrder in myOrders">
                                    <td>{{ myOrder.orderNumber }}</td>
                                    <td>{{ formatDate(myOrder.orderDate) }}</td>
                                    <td>{{ formatAsCurrency(myOrder.orderAmount) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- My Payment Methods -->
                <div class="tab-pane fade" id="my-payment-methods" role="tabpanel">
                    <div v-if="(myPaymentMethods.length === 0)">
                        You have no credit/debit cards on file.
                    </div>
                    <div v-else class="table-responsive font-size-sm">
                        <table class="table table-hover mb-0">
                            <thead>
                            <tr>
                                <th>Credit / Debit Cards</th>
                                <th>Expires on</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(myPaymentMethod, index) in myPaymentMethods">
                                <td class="align-middle"><i class="card-brand pf" v-bind:class="brandClass(myPaymentMethod.card.brand)"></i><span>&nbsp;&nbsp;**** **** **** {{ myPaymentMethod.card.last4 }}&nbsp;&nbsp;</span>
                                    <span v-if="(index === 0)" class="badge badge-pill badge-primary">Default</span>
                                </td>
                                <td class="align-middle">{{ myPaymentMethod.card.expMonth  }} / {{ myPaymentMethod.card.expYear  }}</td>
                                <td class="align-middle" v-if="(index === 0)" style="height: 59px;">
                                    &nbsp;
                                </td>
                                <td v-else class="text-center align-middle">
                                    <button type="button" class="btn btn-sm btn-success mb-2 mr-2" @click.stop="setPaymentMethodAsDefault(myPaymentMethod.id)"><i v-html="checkSvg()"></i>&nbsp;Set as Default</button>
                                    <button type="button" class="btn btn-sm btn-danger mb-2 mr-2" @click.stop="removePaymentMethod(myPaymentMethod.id)"><i v-html="circleSvg()"></i>&nbsp;Remove</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr class="pb-4">
                    <div class="text-sm-left"><a class="btn btn-primary" href="#add-payment" data-toggle="modal">Add payment method</a></div>
                </div>
            </div>
        </div>
        <BlockUI v-if="isProcessing" :message="processMessage">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </BlockUI>
        <!-- Add Payment Method-->
        <form class="needs-validation modal fade" method="post" id="add-payment" tabindex="-1" novalidate>
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Add a payment method</h4>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <p>We accept following credit cards:&nbsp;&nbsp;<img class="d-inline-block align-middle" src="images/cards.png" style="width: 187px;" alt="Cerdit Cards"></p>
                        <div class="row">
                            <div class="col-sm-6">
                                <span class="brand float-right"><i class="pf pf-credit-card" id="brand-icon"></i></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <div id="stripe-card-element" class="stripe-element">
                                </div>
                                <div id="card-number-errors" class="invalid-feedback"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input class="form-control" type="text" v-model.trim="$v.billingName.$model" name="billing-name" placeholder="Full Name" :class="{ 'is-invalid': $v.billingName.$error }" required>
                                <div class="invalid-feedback" v-if="!$v.billingName.required">Please enter the cardholder's name</div>
                            </div>
                            <div class="form-group col-sm-3">
                                <div id="stripe-expiry-element" class="stripe-element"/>
                                <div id="card-expiry-errors" class="invalid-feedback"></div>
                            </div>
                            <div class="form-group col-sm-3">
                                <div id="stripe-cvc-element" class="stripe-element"/>
                                <div id="card-cvc-errors" class="invalid-feedback"></div>
                            </div>
                            <div class="col-sm-6">
                                <button class="btn btn-primary btn-block mt-0" v-bind:class="{ disabled: ! isFormComplete}" @click.prevent="createStripeSource()">Add this card</button>
                            </div>
                            <div class="col-sm-12">
                                <div id="card-errors" role="alert"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import moment from 'moment';
    import feather from 'feather-icons';
    import { formatAsCurrency } from '../helper';
    import {email, helpers, url, required} from 'vuelidate/lib/validators';

    const validPhone = helpers.regex('validPhone', /^\d{3}-\d{3}-\d{4}$/);

    var stripe;
    var elements;
    var cardNumberElement;
    var cardExpiryElement;
    var cardCvcElement;

    var cardBrandToPfClass = {
        'visa': 'pf-visa',
        'mastercard': 'pf-mastercard-alt',
        'amex': 'pf-american-express',
        'discover': 'pf-discover',
        'diners': 'pf-diners-alt',
        'jcb': 'pf-jcb',
        'unionpay': 'pf-unionpay',
        'unknown': 'pf-credit-card',
    };

    export default {
        name: "MyAccount",
        data() {
            return {
                numOfVerificationsRemaining: 0,
                numOfProngChecksRemaining: 0,
                numOfRegistrationRemaining: 0,
                isRetailerProcessing: false,
                retailerProcessMessage: 'Updating...',
                canCreateRetailer: false,
                myRetailer: {
                    id:"",
                    name:"",
                    email:"",
                    retailerPrefix:"",
                    phoneNumber:"",
                    websiteURL:"",
                    videoURL:"",
                },
                myDiamonds: [],
                myOrders: [],
                myPaymentMethods: [],
                isProcessing: false,
                processMessage: 'Updating...',
                billingName: this.$store.state.billingAddress.firstName + ' ' + this.$store.state.billingAddress.lastName,
                isCardComplete: false,
                isExpiryComplete: false,
                isCVCComplete: false,
            }
        },
        validations: {
            billingName: { required },
            myRetailer: {
                name: { required },
                email: { email },
                phoneNumber: { validPhone },
                websiteURL: { url },
                videoURL: { url },
            }
        },
        async created() {
            const accessToken = await this.$auth.getAccessToken();
            const urlUsageData = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/services/usage-data/?productType=retailer');

            axios.get(urlUsageData,
                {
                    crossDomain: true,
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                })
                .then(response => {
                    this.numOfVerificationsRemaining = response.data.numOfDiamondVerificationAvailable - response.data.numOfDiamondVerificationUsed;
                    this.numOfProngChecksRemaining = response.data.numOfDiamondProngCheckAvailable - response.data.numOfDiamondProngCheckUsed;
                    this.numOfRegistrationRemaining = response.data.numOfDiamondRegistrationAvailable - response.data.numOfDiamondRegistrationUsed;
                });

            const urlIDScans = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/idscans/');
            axios.get(urlIDScans,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        this.myDiamonds = response.data;
                    });

            const urlOrders = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/orders/');
            axios.get(urlOrders,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        this.myOrders = response.data;
                    });

            const urlPaymentSources = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/payment-sources/');
            axios.get(urlPaymentSources,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        this.myPaymentMethods = response.data;
                    });

            await this.getRetailerOwnedByUser();
        },
        methods: {
            formatAsCurrency(value) {
                return formatAsCurrency(value);
            },
            addProductToCart(productKey) {
                const product = this.$store.getters.getProductByProductKeyAndPlanKey(productKey, null);
                this.$store.dispatch('addProductToCart', product.id);
            },
            formatDate(value) {
                const dateToFormat = moment(value);
                return dateToFormat.format("MMMM Do, YYYY");
            },
            brandClass(value) {
                switch (value.toLowerCase()) {
                    case('visa'): return 'pf-visa';
                    case('mastercard'): return 'pf-mastercard-alt';
                    case('amex'): return 'pf-american-express';
                    case('american express'): return 'pf-american-express';
                    case('discover'): return 'pf-discover';
                    case('diners'): return 'pf-diners';
                    case('diners club'): return 'pf-diners';
                    case('jcb'): return 'pf-jcb';
                    case('unknown'): return 'pf-credit-card';
                }
            },
            checkSvg() {
                return feather.icons['check'].toSvg();
            },
            circleSvg() {
                return feather.icons['x-circle'].toSvg();
            },
            async setPaymentMethodAsDefault(sourceId) {
                this.isProcessing = true;
                const accessToken = await this.$auth.getAccessToken();
                const urlSetPaymentSourceAsDefault = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/payment-sources/default/');
                var payload = { 'id': sourceId };
                var that = this;
                axios.put(urlSetPaymentSourceAsDefault, payload,
                        {
                            crossDomain: true,
                            headers: {
                                Authorization: 'Bearer ' + accessToken
                            }
                        })
                        .then(response => {
                            that.isProcessing = false;
                            this.getPaymentMethods();
                        });
            },
            async removePaymentMethod(sourceId) {
                this.isProcessing = true;
                const accessToken = await this.$auth.getAccessToken();
                const urlDeletePaymentSource = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/payment-sources/');
                var payload = { 'id': sourceId };
                var that = this;
                axios.delete(urlDeletePaymentSource,
                        {
                            crossDomain: true,
                            headers: {
                                Authorization: 'Bearer ' + accessToken
                            },
                            data: payload
                        })
                        .then(response => {
                            that.isProcessing = false;
                            this.getPaymentMethods();
                        });
            },
            async getPaymentMethods() {
                const accessToken = await this.$auth.getAccessToken();
                const urlRetrievePaymentSources = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/payment-sources/');
                axios.get(urlRetrievePaymentSources,
                        {
                            crossDomain: true,
                            headers: {
                                Authorization: 'Bearer ' + accessToken
                            }
                        })
                        .then(response => {
                            this.myPaymentMethods = response.data;
                        });
            },
            async addPaymentMethod(sourceId) {
                const accessToken = await this.$auth.getAccessToken();
                const urlAddPaymentSource = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/payment-sources/');
                var payload = { 'id': sourceId };
                var that = this;
                axios.post(urlAddPaymentSource, payload,
                        {
                            crossDomain: true,
                            headers: {
                                Authorization: 'Bearer ' + accessToken
                            }
                        })
                        .then(response => {
                            that.isProcessing = false;
                            $('#add-payment').modal('hide');
                            cardNumberElement.clear();
                            cardExpiryElement.clear();
                            cardCvcElement.clear();
                            that.billingName = that.$store.state.billingAddress.firstName + ' ' + that.$store.state.billingAddress.lastName;
                            this.getPaymentMethods();
                        });
            },
            setBrandIcon: function(brand) {
                var brandIconElement = document.getElementById('brand-icon');
                var pfClass = 'pf-credit-card';
                if (brand in cardBrandToPfClass) {
                    pfClass = cardBrandToPfClass[brand];
                }
                for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
                    brandIconElement.classList.remove(brandIconElement.classList[i]);
                }
                brandIconElement.classList.add('pf');
                brandIconElement.classList.add(pfClass);
            },
            createStripeSource: function() {
                this.isProcessing = true;
                var that = this;
                stripe.createSource(cardNumberElement).then(function(result) {
                    if (result.error) {
                        that.isProcessing = false;
                        var errorElement = document.getElementById('card-errors');
                        errorElement.textContent = result.error.message;
                    }
                    else {
                        that.addPaymentMethod(result.source.id);
                    }
                });
            },
            async getRetailerAssignedToUser() {
                const accessToken = await this.$auth.getAccessToken();
                const userAssignedRetailer = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/');
                axios.get(userAssignedRetailer,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        if(response.data.retailer == null){
                            this.getRetailerOwnedByUser();
                        } else {
                            this.myRetailer = response.data.retailer;
                        }
                    });
            },
            async getRetailerOwnedByUser() {
                const accessToken = await this.$auth.getAccessToken();
                const userOwnedRetailer = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'retailers/owners/' + this.$auth.profile.sub + '/');
                axios.get(userOwnedRetailer,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                            this.myRetailer = response.data;
                    })
                    .catch(error => {
                        if( error.response.data.includes("No retailer") && error.response.status === 404) {
                            this.canCreateRetailer = true;
                        }
                    });
            },
            async updateRetailer(e) {
                this.isRetailerProcessing = true;
                const accessToken = await this.$auth.getAccessToken();
                const updateRetailer = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'retailers/');
                var payload =  this.myRetailer;
                axios.put(updateRetailer, payload,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        this.isRetailerProcessing = false;
                        this.myRetailer = response.data;
                    })
                    .catch(error => {
                        this.isRetailerProcessing = false;
                        console.log(error.response.data)
                    });
            },
            async createRetailer(e) {
                this.isRetailerProcessing = true;
                this.retailerProcessMessage = "Creating..."
                const accessToken = await this.$auth.getAccessToken();
                const updateRetailer = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'retailers/');
                var payload = {
                    "user" : {
                        "sub" : this.$auth.profile.sub
                    },
                    "name": this.myRetailer.name ,
                    "email": this.myRetailer.email,
                    "phoneNumber": this.myRetailer.phoneNumber,
                    "websiteURL":  this.myRetailer.websiteURL,
                    "videoURL": this.myRetailer.videoURL
                };
                axios.post(updateRetailer, payload,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        this.isRetailerProcessing = false;
                        this.retailerProcessMessage = "Updating..."
                        this.myRetailer = response.data;
                    })
                    .catch(error => {
                        this.isRetailerProcessing = false;
                        console.log(error.response.data)
                    });
            },
            formatPhone(event) {
                    var phoneString = event.target.value.replace(/\D/g, "");
                    phoneString = phoneString.substring(0, 10);

                    if (phoneString.length > 6) {
                        phoneString = phoneString.slice(0,3) + "-" + phoneString.slice(3,6)+"-" + phoneString.slice(6);
                    }
                    else if (phoneString.length > 3) {
                        phoneString = phoneString.slice(0,3) + "-" + phoneString.slice(3,6);
                    }
                this.myRetailer.phoneNumber = phoneString;
                event.target.value = phoneString;
            },
        },
        computed: {
            userName() {
                return (this.$auth.profile.name) ? this.$auth.profile.name : this.$auth.profile.email;
            },
            email() {
                return this.$auth.profile.email;
            },
            retailerRegistrationPrice() {
                const product = this.$store.getters.getProductByProductKeyAndPlanKey('RETAILER_REGISTRATION', null);
                return formatAsCurrency(product.price);

            },
            retailerVerificationPrice() {
                const product = this.$store.getters.getProductByProductKeyAndPlanKey('RETAILER_VERIFICATION', null);
                return formatAsCurrency(product.price);
            },
            isFormComplete() {
                return (this.isCardComplete && this.isExpiryComplete && this.isCVCComplete && this.billingName);
            },
            isDisabled() {
                return (this.myRetailer.name !== '');
            },
        },
        mounted() {
            feather.replace();

            stripe = Stripe(process.env.MIX_STRIPE_PUBLISHABLE_KEY);
            elements = stripe.elements({
                fonts: [{
                    cssSrc: 'https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800'
                }]
            });

            var style = {
                base: {
                    'fontFamily': 'Muli',
                    'fontSize': '16px',
                    'color': '#404040',
                    'fontWeight': '400',
                    'fontSmoothing': 'antialiased',
                    'lineHeight': 1.5,
                },
                invalid: {
                    color: '#f44336',
                    iconColor: '#cc0000'
                }
            };

            cardNumberElement = elements.create('cardNumber', {style: style});
            cardNumberElement.mount('#stripe-card-element');

            var that = this;
            cardNumberElement.on('change', function(event) {
                if (event.brand) {
                    that.setBrandIcon(event.brand);
                }
                var displayCardError = document.getElementById('card-number-errors');
                displayCardError.textContent = (event.error) ? event.error.message : '';
                displayCardError.style.display = (event.error) ? "block" : "none";
                that.isCardComplete = event.complete;
            });

            cardExpiryElement = elements.create('cardExpiry', {style: style});
            cardExpiryElement.mount('#stripe-expiry-element');

            cardExpiryElement.on('change', function(event) {
                var displayCardExpiryError = document.getElementById('card-expiry-errors');
                displayCardExpiryError.textContent = (event.error) ? event.error.message : '';
                displayCardExpiryError.style.display = (event.error) ? "block" : "none";
                that.isExpiryComplete = event.complete;
            });

            cardCvcElement = elements.create('cardCvc', {style: style});
            cardCvcElement.mount('#stripe-cvc-element');

            cardCvcElement.on('change', function(event) {
                var displayCardCVCError = document.getElementById('card-cvc-errors');
                displayCardCVCError.textContent = (event.error) ? event.error.message : '';
                displayCardCVCError.style.display = (event.error) ? "block" : "none";
                that.isCVCComplete = event.complete;
            })
        }
    }
</script>

<style scoped>
    .card-brand {
        font-size: 18px !important;
    }

    .stripe-element {
        display: block;
        width: 100%;
        height: calc(1.5em + 1rem + 2px);
        padding: 0.5rem 1rem;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e1e1e1;
        border-radius: 0;
        flex: 1;
        transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }

    .stripe-element:focus,
    .stripe-element.StripeElement--focus {
        color: #404040;
        background-color: #fff;
        border-color: transparent;
        outline: 0;
        box-shadow: 0 0 0 0.0625rem rgba(92, 119, 252, 0.35);
    }

    .StripeElement--invalid {
        border-color: #f44336;
    }

    span.brand {
        width: 30px;
    }

    #card-errors {
        padding: 4px 0;
        color: #f44336;
    }
</style>
