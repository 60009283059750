<template>
    <div>
        <div class="container pb-5 mb-1">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <h2 class="mt-4 text-center">The DiamondEye Viewer</h2>
                    <div class="row mt-4 my-4">
                        <div class="text-center col-md-4 offset-md-4">
                            <button class="btn btn-primary px-5 mr-2" @click.stop="addProductToCart()"><i class="mr-2" data-feather="shopping-cart"></i>Order DiamondEye</button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-8 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <ul>
                                        <li class="mb-2">Register your diamonds with DiamondEye​</li>
                                        <li class="mb-2">The specialized DiamondEye Viewer uses the autofocus of the front camera to record the characteristics of the diamond</li>
                                        <li class="mb-2">Diamond information is saved in the SkyVault&#153; Database​</li>
                                        <li class="mb-2">Instantly verify your diamonds at any time with automated processing and independent verification​​</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-4"><img class="mb-grid-gutter viewer-image" src="images/diamondeye-viewer.jpg" alt=""></div>
                    </div>
                    <div class="row pb-2 mt-4">
                        <div class="col-sm-6"><img class="mb-grid-gutter" src="images/diamondeye-viewer6.jpg" alt=""></div>
                        <div class="col-sm-6"><img class="mb-grid-gutter" src="images/diamondeye-viewer5.jpg" alt=""></div>
                    </div>
                    <div class="row mt-4 my-4">
                        <div class="text-center col-md-4 offset-md-4">
                            <button class="btn btn-primary px-5 mr-2" @click.stop="addProductToCart()"><i class="mr-2" data-feather="shopping-cart"></i>Order DiamondEye</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from './../router';
    import feather from 'feather-icons';
    import StartHereButton from "../components/StartHereButton";

    export default {
        name: "DiamondEyeViewerPage",
        components: {StartHereButton},
        mounted() {
            feather.replace();
        },
        methods: {
            addProductToCart() {
                this.$store.dispatch('addProductToCart', this.DIAMOND_VIEWER_KIT_PRODUCT_ID);
                router.push({ path: '/cart' });
            }
        },
        created() {
            this.DIAMOND_VIEWER_KIT_PRODUCT_ID = 13;
        }
    }
</script>

<style scoped>
    .viewer-image {
        height: 280px;
        max-height: 100%
    }

</style>
