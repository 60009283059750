export const retrieveIdentiviewUserMixin = {

    data() {
        return {
            billingAddress: Object.assign({}, this.$store.state.billingAddress),
            currentPlan: Object.assign({}, this.$store.state.currentPlan)
        }
    },
    methods: {
        async retrieveOrCreateIdentiviewUser(auth0UserId) {
            const accessToken = await this.$auth.getAccessToken();
            const url = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + auth0UserId + '/');
            return axios.get(url,
                {
                    crossDomain: true,
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                })
                .then(response => {
                    this.billingAddress.emailAddress = response.data.email;

                    if (response.data.name) {
                        var names = response.data.name.split(' ').filter(function(v) {
                            return v !== ''
                        });

                        if (names.length > 1) {
                            this.billingAddress.firstName = names.slice(0, names.length - 1).join(" ");
                            this.billingAddress.lastName = names[names.length - 1];
                        }
                        else if (names.length === 1) {
                            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            if (!re.test(String(names[0]).toLowerCase())) {
                                this.billingAddress.firstName = names[0];
                            }
                        }
                    }

                    if (response.data.currentSubscriptionPlan) {
                        this.currentPlan.id = response.data.currentSubscriptionPlan.product.id;
                        this.currentPlan.name = response.data.currentSubscriptionPlan.product.name;
                        this.currentPlan.description = response.data.currentSubscriptionPlan.product.description;
                        this.currentPlan.productKey = response.data.currentSubscriptionPlan.product.productKey;
                        this.currentPlan.price = response.data.currentSubscriptionPlan.product.price;
                        this.currentPlan.subscriptionEndDateTime = response.data.currentSubscriptionPlan.subscriptionEndDateTime;

                        var that = this;
                        response.data.currentSubscriptionPlan.product.productServices.forEach(function(productService) {
                            switch (productService.service.serviceKey) {
                                case ("DIAMOND_REGISTRATION"):
                                    that.currentPlan.numOfRegistrationAllowed = productService.numOfUsageAllowed;
                                    that.currentPlan.additionalRegistrationPrice = productService.additionalUsagePrice;
                                    break;
                                case ("DIAMOND_COMPARISON"):
                                    that.currentPlan.numOfVerificationsAllowed = productService.numOfUsageAllowed;
                                    that.currentPlan.additionalVerificationPrice = productService.additionalUsagePrice;
                                    break;
                                case ("DIAMOND_PRONG_CHECK"):
                                    that.currentPlan.numOfProngChecksAllowed = productService.numOfUsageAllowed;
                                    that.currentPlan.additionalProngCheckPrice = productService.additionalUsagePrice;
                                    break;
                            }
                        });
                    }

                    this.$store.dispatch('setBillingAddress', this.billingAddress);
                    this.setCurrentPlan(this.currentPlan);

                    // TODO: response.data provides the rest
                    // need to handle errors and store what is necessary
                });
        },
        async setCurrentPlan(currentPlan) {
            await this.$store.dispatch('setCurrentPlan', currentPlan);
            if (this.$store.state.isPlanInCartRemoved) {
                this.$modal.show('dialog', {
                    text: 'You are already subscribing to a higher or same plan as the one you have added to the cart. We have removed the plan in the cart.',
                    buttons: [{
                        title: 'Close',
                        class: 'btn btn-info'
                    }
                    ]
                });
                this.$store.dispatch('clearIsPlanInCartRemoved');
            }
        }
    }
};
