<template>
    <div class="col-xl-3 col-md-4 pt-4 mt-3 pt-md-0 mt-md-0">
        <h2 class="h6 px-4 py-3 bg-secondary text-center">Order summary</h2>
        <div class="font-size-sm border-bottom pt-2 pb-3">
            <div class="d-flex justify-content-between mb-2"><span>Subtotal:</span><span>{{ totalCartAmount }}</span></div>
            <div class="d-flex justify-content-between mb-2"><span>Shipping:</span><span>{{ shippingAmount }}</span></div>
            <template v-if="discountCodeApplied">
                <div class="d-flex justify-content-between mb-2"><span>Discount ({{ discountCodeApplied }}):</span><span>{{ discountAmount }}</span></div>
            </template>
            <!-- <div class="d-flex justify-content-between mb-2"><span>Taxes:</span><span>{{ totalOrderAmount }}</span></div> -->
        </div>
        <div class="h3 font-weight-semibold text-center py-3">{{ totalOrderAmount }}</div>
        <hr>
        <div class="pt-4" v-if="showPromoCodeForm">
            <div class="accordion" id="cart-accordion">
                <div class="card">
                    <div class="card-header">
                        <h3 class="accordion-heading font-weight-semibold"><a href="#promocode" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="promocode">Apply promo code<span class="accordion-indicator"><i data-feather="chevron-up"></i></span></a></h3>
                    </div>
                    <div class="collapse show" id="promocode" data-parent="#cart-accordion">
                        <div class="card-body">
                            <form class="needs-validation" novalidate>
                                <div class="form-group">
                                    <input class="form-control" type="text" v-model.trim="promoCode" v-on:keyup="clearPromoCodeMessage" name="promo-code" placeholder="Promo code" :class="{ 'is-invalid': showInvalidPromoCodeMessage }">
                                    <div v-if="showInvalidPromoCodeMessage" class="invalid-feedback">Please provide a valid promo code</div>
                                </div>
                                <button class="btn btn-outline-primary btn-block" v-bind:class="{ disabled: isMissingPromoCode }" :disabled="isMissingPromoCode" @click.stop.prevent="applyPromoCode()">Apply promo code</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="card">
                </div>
            </div>
        </div>
        <BlockUI v-if="isProcessing" :message="processMessage">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </BlockUI>
    </div>
</template>

<script>
    import { formatAsCurrency } from '../helper';

    export default {
        name: "CheckoutOrderSummary",
        props: {
            showPromoCodeForm: Boolean
        },
        data() {
            return {
                promoCode: null,
                isProcessing: false,
                invalidPromoCode: false,
                shippingAddress: Object.assign({}, this.$store.state.shippingAddress),
                billingAddress: Object.assign({}, this.$store.state.billingAddress),
                stripeSource: this.$store.state.stripeSource,
                processMessage: 'Calculating discount. Please wait...'
            }
        },
        computed: {
            cartItems() {
                return this.$store.state.cart;
            },
            totalCartAmount() {
                return formatAsCurrency(this.$store.getters.getTotalCartAmount);
            },
            shippingAmount() {
                return formatAsCurrency(this.$store.state.shippingAmount);
            },
            discountCodeApplied() {
                return this.$store.state.discountCodeApplied;
            },
            discountAmount() {
                return formatAsCurrency(this.$store.state.discountAmount);
            },
            totalOrderAmount() {
                return formatAsCurrency(this.$store.getters.getTotalOrderAmount);
            },
            isMissingPromoCode() {
                return (! this.promoCode);
            },
            showInvalidPromoCodeMessage() {
                return this.invalidPromoCode;
            }
        },
        methods: {
            clearPromoCodeMessage: function() {
                this.invalidPromoCode = false;
            },
            applyPromoCode: async function () {
                this.isProcessing = true;
                const accessToken = await this.$auth.getAccessToken();
                const url = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/orders/discountcode/');

                var payload = {};
                var order = {};
                order.shipAddressFirstName = this.shippingAddress.firstName;
                order.shipAddressLastName = this.shippingAddress.lastName;
                order.shipAddressEmailAddress = this.shippingAddress.emailAddress;
                order.shipAddressPhone = this.shippingAddress.phone;
                order.shipAddressAddress1 = this.shippingAddress.address1;
                order.shipAddressAddress2 = this.shippingAddress.address2;
                order.shipAddressCity = this.shippingAddress.city;
                order.shipAddressState = this.shippingAddress.state;
                order.shipAddressPostalCode = this.shippingAddress.postalcode;
                order.shipAddressCountry = this.shippingAddress.country;
                order.billAddressFirstName = this.billingAddress.firstName;
                order.billAddressLastName = this.billingAddress.lastName;
                order.billAddressEmailAddress = this.billingAddress.emailAddress;
                order.billAddressPhone = this.billingAddress.phone;
                order.billAddressAddress1 = this.billingAddress.address1;
                order.billAddressAddress2 = this.billingAddress.address2;
                order.billAddressCity = this.billingAddress.city;
                order.billAddressState = this.billingAddress.state;
                order.billAddressPostalCode = this.billingAddress.postalcode;
                order.billAddressCountry = this.billingAddress.country;
                order.shippingAmount = this.$store.state.shippingAmount;
                order.discountCodeApplied = this.promoCode;
                payload.order = order;
                payload.stripeSource = this.stripeSource;

                var orderItems = [];
                var cartItems = this.cartItems;
                cartItems.forEach(function (cartItem) {
                    var orderItem = {};
                    orderItem.productID = cartItem.productId;
                    orderItem.quantity = cartItem.qty;
                    orderItems.push(orderItem);
                });
                payload.orderItems = orderItems;

                // Need to add access token
                // https://auth0.com/docs/quickstart/spa/vuejs/03-calling-an-api#call-the-api-using-an-access-token
                var that = this;
                return axios.post(url, payload,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        that.isProcessing = false;
                        this.$store.dispatch('setDiscountAmount', response.data.discountAmount);
                        this.$store.dispatch('setDiscountCodeApplied', this.promoCode);
                    })
                    .catch(error => {
                        this.isProcessing = false;
                        if (error.response) {
                            if (error.response.status === 400) {
                                this.invalidPromoCode = true;
                            }
                            console.log(error.response);
                        }
                        else if (error.request) {
                            console.log(error.request);
                        }
                        else {
                            console.log(error.message);
                        }
                    });
            }
        }
    }
</script>

<style scoped>

</style>
