<template>
    <div>
        <div class="container pb-5 mb-1">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row mt-5">
                        <div class="col-sm-6 text-center">
                            <img class="mb-grid-gutter" src="images/logo.jpg" alt="">
                        </div>
                        <div class="col-sm-6 text-center">
                            <h1 class="page-title pt-1">DiamondEye<sup>&reg;</sup></h1>
                            <h2 class="font-weight-light pt-3 pb-2">Diamond ID Made Easy</h2>
                            <!-- <p class="text-center" style="font-family: 'Oooh Baby', cursive; font-size: 32px; color: red; font-weight: bold;">See you at CBG in Orlando!</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">

                    <h4 class="text-center">DiamondEye is a patented iPad/iPhone based system that quickly identifies individual diamonds and inspects diamond mountings!</h4>

                    <div class="row justify-content-center my-5">
                        <div class="col-lg-9">
                            <div class="card">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/qIaxtyhAbJ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4 my-4">
                        <div class="text-center col-md-4 offset-md-4">
                            <button class="btn btn-primary px-5 mr-2" @click.stop="addProductToCart()"><i class="mr-2" data-feather="shopping-cart"></i>Order Now</button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-4 mt-4"><img class="mb-grid-gutter" src="images/women-looking-at-diamonds.jpg" alt=""></div>
                        <div class="col-sm-8 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">When a customer returns a diamond for credit or refund, can you answer all these questions?</h5>
                                    <ul>
                                        <li class="card-text">
                                            Is it the same diamond that you sold them?<br/>
                                            <a href="https://en.israelidiamond.co.il/news/world/swapped-diamonds-for-fakes/" target="_blank">Michigan: Man who swapped diamonds for fakes gets prison time</a>
                                        </li>
                                        <li class="card-text">
                                            Did they buy a natural diamond and return a lab grown diamond?<br/>
                                            <a href="https://www.nationaljeweler.com/diamonds-gems/grading/6026-gia-reports-finding-lab-grown-diamond-with-fake-inscription" target="_blank">GIA Reports Finding Lab-Grown Diamond with Fake Inscription</a>
                                        </li>
                                        <li class="card-text">
                                            Is it a diamond simulant?
                                        </li>
                                        <li class="card-text">
                                            Can you recognize a counterfeit girdle engraving?<br/>
                                            <a href="https://diamondimports.wordpress.com/2007/11/27/trade-alert-fake-gia-laser/" target="_blank">Fake GIA laser inscription discovered in Melbourne</a>
                                        </li>
                                        <li class="card-text">
                                            Will your store personnel be able to verify it is the same diamond?
                                        </li>
                                    </ul>
                                    <p class="card-text">With DiamondEye™, all of the above will be answered in seconds.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-8 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">Can you confirm this information when your store takes in a diamond ring for repair?</h5>
                                    <ul>
                                        <li class="card-text">
                                            Is it possible the customer thinks that the returned diamond is not the original?
                                        </li>
                                        <li class="card-text">
                                            Did your associates return the same diamond to the customer?
                                        </li>
                                        <li class="card-text">
                                            Can you prove it to the customer?
                                        </li>
                                    </ul>
                                    <p class="card-text">
                                        With DiamondEye™, both you and your customer receive 3rd party verification.
                                    </p>
                                    <p class="card-text">
                                        This will protect you and instill additional trust and confidence in your customer.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-4"><img class="mb-grid-gutter" src="images/ring-repair.jpg" alt=""></div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-12 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">Sell or gift DiamondEye to customers.</h5>
                                    <p class="card-text">Customers will use DiamondEye monthly to check their mountings. When they do, they will see a 30 second message/video from you. This message can be updated as often as you wish. The app will remind them every month to do a prong check.</p>
                                    <p class="card-text">If an issue with the prongs on the ring is detected, the customer is instructed to contact your store with a one click phone call. You will be sent an image of the moved or damaged prong that you can review with the customer.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                        </div>
                        <div class="col-sm-8"><img class="mb-grid-gutter" src="images/prong-comparison.jpg" alt=""></div>
                        <div class="col-sm-12 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <p class="card-text">Ask us for more information: <a href="mailto:info@gemex.com">info@gemex.com</a></p>
                                    <div class="text-center pt-4 col-md-4 offset-md-4">
                                        <button class="btn btn-primary px-5" @click.stop="addProductToCart()"><i class="mr-2" data-feather="shopping-cart"></i>Order DiamondEye</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-12 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">What is DiamondEye?</h5>
                                    <p class="card-text">The patented DiamondEye is a new way to protect and verify diamonds and the mountings using a iPhone or iPad with a specialized DiamondEye Viewer.</p>
                                    <p class="card-text">DiamondEye is easy to use.  First, order your registration kit.  Then, register your diamond in the SkyVault database using the DiamondEye App, the Viewer and the front camera on your iPhone or iPad. </p>
                                    <p class="card-text">Verify your diamond using the same process. The DiamondEye algorithm will compare and confirm the verification diamond is the same as the original. You will receive verification within seconds.</p>
                                    <p class="card-text">Checking the prongs/mountings is a similar process. Within seconds the algorithms will check the settings and provide a condition report.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-5">
                        <div class="card-body">
                            <h3 class="card-title text-center">Download the App</h3>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <a href="https://apps.apple.com/id/app/diamondeyepro/id6479329165" class="market-btn apple-btn" role="button">
                                        <span class="market-button-subtitle">Download on the</span>
                                        <span class="market-button-title">App Store</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-5">
                        <div class="text-center col-md-4 offset-md-4">
                            <a href="https://gemex.com/user-guide-for-diamondeye-retailer/" target="_blank" class="btn btn-primary px-5 mr-2"><i class="mr-2" data-feather="book-open"></i>How to Use DiamondEye</a>
                        </div>
                    </div>
                    <div class="row pb-2 mt-4">
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3">
                            <img class="mb-grid-gutter" src="images/diamondeye-retailer-1.jpg" height="300px" alt="">
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3">
                            <img class="mb-grid-gutter" src="images/diamondeye-retailer-2.jpg" height="300px" alt="">
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3">
                            <img class="mb-grid-gutter" src="images/diamondeye-retailer-3.jpg" height="300px" alt="">
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3">
                            <img class="mb-grid-gutter" src="images/diamondeye-retailer-4.jpg" height="300px" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import feather from 'feather-icons';
    import StartHereButton from "../components/StartHereButton";
    import router from "../router";

    export default {
        name: "HomePage",
        components: {StartHereButton},
        mounted() {
            feather.replace();
        },
        methods: {
            addProductToCart() {
                this.$store.dispatch('addProductToCart', this.DIAMOND_VIEWER_KIT_PRODUCT_ID);
                router.push({ path: '/cart' });
            }
        },
        created() {
            this.DIAMOND_VIEWER_KIT_PRODUCT_ID = 13;
        }
    }
</script>

<style scoped>
    a[aria-expanded=true] .plus-circle {
        display: none;
    }
    a[aria-expanded=false] .minus-circle {
        display: none;
    }

    .card-header-text {
        margin-bottom: 2px;
        display: inline;
    }
</style>
